import React, { Fragment } from 'react';
import {
    message,
    Card,
    List,
    Pagination,
    Skeleton,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import TransactionItem from './TransactionItem';
import { doRequest } from '@explorer-utils/request';

import './AddressView.less'

const AddressView = withRouter(({ match }) => {
    const address = match.params.address;

    const [account, setAccount] = React.useState(null);
    const [blackHolder, setBlackHolder] = React.useState(false);

    const txCountOfPage = 5;
    const [loadingTx, setLoadingTx] = React.useState(false);
    const [transactions, setTransactions] = React.useState(null);
    const [txPage, setTxPage] = React.useState(1);
    const [txTotal, setTxTotal] = React.useState(0);

    React.useEffect(() => {
        doRequest.get({
            url: `/api/blockchain/address/${address}`,
        }).then(result => {
            setAccount(result.data);
            setBlackHolder(result.data.address == "334L4RPCoYrrzLZ57xMEz64xgtVEyHNMAX");
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        });
    }, [address]);
    React.useEffect(() => {
        setLoadingTx(true);
        doRequest.get({
            url: `/api/blockchain/address/${address}/transaction`,
            data: {
                page: txPage - 1,
                count: txCountOfPage,
            }
        }).then(result => {
            setTransactions(result.data.data);
            setTxTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoadingTx(false));
    }, [address, txPage]);

    const BaseTitle = () => (
        <div className="explorer-addressview-base-title explorer-title explorer-address">
            <FormattedMessage id="addressview.title" />
        </div>
    );
    const TransactionsTitle = () => (
        <div className="explorer-addressview-txs-title explorer-title ">
            <FormattedMessage id="addressview.txs" />
        </div>
    );
    return (
        <div className='explorer-addressview'>
            <Skeleton loading={!account}>
                <Card
                    className="explorer-addressview-base"
                    title={<BaseTitle />}
                >
                    <div className="explorer-addressview-address explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="addressview.address" /></span>
                        <span className="explorer-box-content">
                            <span className="explorer-address">{account ? account.address : ''}</span>
                            {account && account.stakingpool && (
                                <a
                                    className="explorer-addressview-stakingpoolusers"
                                    href={`/blockchain/address/${address}/stakingpoolusers`}
                                >
                                <FormattedMessage id="addressview.viewstakingpoolusers" />
                            </a>
                            )}
                            {blackHolder && (
                                <span className="explorer-addressview-warning"><FormattedMessage id="addressview.thisABlackHoldAddress" /></span>
                            )}
                        </span>
                    </div>
                    <div className="explorer-addressview-amount explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="addressview.amount" /></span>
                        <span className="explorer-box-content">
                            <span className="explorer-amount">{account ? account.balance : ''} QTC</span>
                            <a
                                className="explorer-addressview-coins"
                                href={`/blockchain/address/${address}/coins`}
                                >
                                <FormattedMessage id="addressview.viewcoins" />
                            </a>
                        </span>
                    </div>
                    { !blackHolder && (
                        <div className="explorer-addressview-spendable explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="addressview.spendable" /></span>
                            <span className="explorer-box-content">
                                <span className="explorer-amount">{account ? account.spendable : ''} QTC</span>
                            </span>
                        </div>
                    )}
                    {account && account.stakingfrom && account.stakingfrom !== "0.00000000" && !blackHolder && (
                        <div className="explorer-addressview-stakingfrom explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="addressview.stakingfrom" /></span>
                            <span className="explorer-box-content">
                                <span className="explorer-amount">{account ? Math.abs(account.stakingfrom - (account.stakingpool ? 10000 : 0)) : ''} QTC</span>
                            </span>
                        </div>
                    )}
                    {account && account.stakingto && account.stakingto !== "0.00000000" && !blackHolder && (
                        <div className="explorer-addressview-stakingto explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="addressview.stakingto" /></span>
                            <span className="explorer-box-content">
                                <span className="explorer-amount">{account ? account.stakingto : ''} QTC</span>
                                <a
                                    className="explorer-addressview-userstakingpools"
                                    href={`/blockchain/address/${address}/userstakingpools`}
                                >
                                    <FormattedMessage id="addressview.viewuserstakingpools" />
                                </a>
                            </span>
                        </div>
                    )}
                    {account && account.pointfrom && account.pointfrom !== "0.00000000" && !blackHolder && (
                        <div className="explorer-addressview-pointfrom explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="addressview.pointfrom" /></span>
                            <span className="explorer-box-content">
                                <span className="explorer-amount">{account ? account.pointfrom : ''} QTC</span>
                            </span>
                        </div>
                    )}
                    {account && account.pointto && account.pointto !== "0.00000000" && !blackHolder && (
                        <div className="explorer-addressview-pointto explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="addressview.pointto" /></span>
                            <span className="explorer-box-content">
                                <span className="explorer-amount">{account ? account.pointto : ''} QTC</span>
                            </span>
                        </div>
                    )}
                    <div className="explorer-addressview-txn explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="addressview.txn" /></span>
                        <span className="explorer-box-content">{account ? account.tx : ''}</span>
                    </div>
                    {account && account.miningblock > 0 && !blackHolder && (
                        <Fragment>
                            <div className="explorer-addressview-miningblock explorer-box">
                                <span className="explorer-box-title"><FormattedMessage id="addressview.miningblock" /></span>
                                <span className="explorer-box-content">
                                    {account ? account.miningblock : ''}
                                    (<FormattedMessage id="addressview.miningblock.percent" /> {new Number((account ? account.miningblock : 0) * 100 / 3306).toFixed(2)}%)</span>
                            </div>
                        </Fragment>
                    )}
                </Card>
            </Skeleton>
            <Skeleton loading={!transactions}>
                <Card
                    className="explorer-addressview-txs"
                    title={<TransactionsTitle />}
                >
                    <List
                        loading={loadingTx}
                        dataSource={transactions}
                        renderItem={(item, index) => <TransactionItem data={item} index={(txPage - 1) * txCountOfPage + index} compact={true} />}
                    />
                    <Pagination
                        className="explorer-pagination"
                        current={txPage}
                        total={txTotal}
                        pageSize={txCountOfPage}
                        onChange={(v) => setTxPage(v)}
                        showSizeChanger={false}
                    />
                </Card>
            </Skeleton>
        </div>
    )
});

export default AddressView;