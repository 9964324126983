
const eclipseHash = (hash) => {
    if (!hash || hash.length < 32) {
        return hash;
    }

    return hash.substring(0, 8) + '...' + hash.substring(hash.length-8)
}

const formatSize = (byte) => {
    if (byte <= 1024) {
        return byte + " byte"
    }

    if (byte <= 1024 * 1024) {
        return new Number(byte / 1024).toFixed(2) + " KiB"
    }

    return new Number(byte / 1024 / 1024).toFixed(2) + " MiB"
}

const formatTime = (timestamp) => {
    let d = new Date(timestamp * 1000);
    return d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0') + ' ' + d.toLocaleTimeString();
}


export { eclipseHash, formatSize, formatTime }