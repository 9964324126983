export default {
    // lang
    "lang.en-US": "English",
    "lang.zh-CN": "简体中文",
    // components
    "components.timer.seconds": "秒",
    "components.timer.minutes": "分",
    "components.timer.hours": "小时",
    "components.timer.days": "天",
    // error
    "error.gotohome": "回到主页",
    "error.message404": "找不到匹配的资源",
    // app
    "app.copyright": "Copyright © 2025 qitcoin.io. 保留所有权利。",
    // header
    "header.homepage": "首页",
    "header.blockchain.blocks": "区块",
    "header.blockchain.transactions": "交易",
    "header.blockchain.capacity": "容量排行",
    "header.blockchain.accounts": "富豪榜",
    "header.blockchain.stakingpools": "节点池",
    "header.blockchain.network.label": " ",
    "header.blockchain.network.mainnet": " ",
    "header.search.placeholder": "搜索 区块高度/区块哈希/交易哈希/地址/算力表示",
    // footer
    "footer.link.org": "Qitcoin 官网",
    "footer.wallet.sourcecode": "Qitcoin 源代码",
    "footer.wallet.download": "下载 Qitcoin 钱包",
    "footer.link.coinmarketcap":"CoinMarketCap (CMC)",
    "footer.link.feixiaohao":"非小号",
    "footer.link.bitnasdaq":"Bitnasdaq",
    "footer.link.coincarp":"CoinCarp",
    "footer.link.balletcrypto":"Ballet Wallet",
    // dashboard
    "dashboard.network.title": "全网概览",
    "dashboard.network.name": "当前网络",
    "dashboard.network.height": "区块高度",
    "dashboard.network.blockAge": "最新区块年龄",
    "dashboard.network.blockSyncing": "同步中",
    "dashboard.network.capacity": "全网难度",
    "dashboard.network.totalStaking": "质押总量",
    "dashboard.network.blockReward": "区块奖励",
    "dashboard.network.users": "独立地址数",
    "dashboard.network.epochBlocks": "每周期区块数",
    "dashboard.network.nextHalvingBlocks": "减半剩余区块",
    "dashboard.network.nextHalvingTime": "减半剩余时间",
    "dashboard.network.mined": "已产出总量",
    "dashboard.network.notMined": "未产出总量",
    "dashboard.network.dailyIncome": "每日收益(100 QTC)",
    "dashboard.network.totalSupply": "供给总量",
    "dashboard.network.circulation": "流通总量",
    "dashboard.network.circulationRate": "流通率",
    "dashboard.network.destroyAmount": "销毁总量",
    "dashboard.difficulty.title": "难度走势",
    "dashboard.blocks.title": "最近区块",
    // capacity
    "difficulty.range.week": "周",
    "difficulty.range.month": "月",
    "difficulty.range.year": "年",
    "difficulty.range.quarter": "季",
    "difficulty.range.all": "所有",
    // block list
    "blocklist.height": "高度",
    "blocklist.time": "出块时间",
    "blocklist.tx": "交易数",
    "blocklist.generator": "出块地址",
    // block view
    "blockview.title": "区块信息",
    "blockview.previous": "上一个",
    "blockview.next": "下一个",
    "blockview.height": "区块高度",
    "blockview.hash": "区块哈希",
    "blockview.time": "区块时间",
    "blockview.generator": "出块者",
    "blockview.nonce": "答案",
    "blockview.deadline": "答案质量",
    "blockview.difficulty": "难度",
    "blockview.reward": "产出总额",
    "blockview.txs": "打包的交易",
    // tx item
    "txitem.time": "交易时间",
    "txitem.from": "发送地址",
    "txitem.to": "接收地址",
    "txitem.amount": "交易总额",
    "txitem.fee": "交易费用",
    "txitem.size": "交易尺寸",
    "txitem.coinbase": "出块奖励",
    "txitem.blockhash": "区块哈希",
    "txitem.vout.MinedReward": "出块奖励",
    "txitem.vout.BindPlotter": "绑定算力",
    "txitem.vout.Point": "抵押指向",
    "txitem.vout.Staking": "授权",
    "txitem.vout.receiver": "接收地址: ",
    "txitem.vout.amount": "有效金额: ",
    "txitem.vout.lockBlocks": "锁定高度: ",
    // address view
    "addressview.title": "地址信息",
    "addressview.address": "地址",
    "addressview.viewstakingpoolusers": "查看池用户",
    "addressview.viewuserstakingpools": "查看所属的池",
    "addressview.amount": "总金额",
    "addressview.viewcoins": "查看明细",
    "addressview.spendable": "可用总额",
    "addressview.txn": "交易总数",
    "addressview.stakingfrom": "收到的授权",
    "addressview.stakingto": "发出的授权",
    "addressview.pointfrom": "收到的质押",
    "addressview.pointto": "发出的质押",
    "addressview.miningblock": "周产出区块数",
    "addressview.miningblock.percent": "占比",
    "addressview.bindcount": "绑定的算力数",
    "addressview.capacity": "评估的算力",
    "addressview.miningrequire": "需要的质押",
    "addressview.txs": "相关交易",
    "addressview.thisABlackHoldAddress": "这是一个黑洞地址",
    // accounts
    "accounts.type.account": "富豪榜",
    "accounts.type.pool": "节点池",
    "accounts.NO": "排位",
    "accounts.address": "地址",
    "accounts.amount": "金额",
    "accounts.summary.amount": "总金额",
    "accounts.summary.total": "总数",
    // coin list
    "coinlist.title": "零钱明细",
    "coinlist.address": "地址",
    "coinlist.total": "零钱总数",
    "coinlist.txid": "交易",
    "coinlist.amount": "金额",
    "coinlist.type": "类型",
    "coinlist.type.Coinbase": "出块奖励",
    "coinlist.type.Pay": "支付",
    "coinlist.type.BindPlotter": "绑定算力",
    "coinlist.type.Point": "指向",
    "coinlist.type.Staking": "授权",
    // user staking pools
    "userstakingpools.title": "用户的节点池",
    "userstakingpools.address": "池地址",
    "userstakingpools.stakingAmount": "授权金额",
    "userstakingpools.withdrawableAmount": "奖励金额",
    "userstakingpools.total": "总数",
    // staking pool users
    "stakingpoolusers.title": "节点池中的用户",
    "stakingpoolusers.address": "用户地址",
    "stakingpoolusers.stakingAmount": "授权金额",
    "stakingpoolusers.withdrawableAmount": "奖励金额",
    "stakingpoolusers.total": "总数",
}