import AddressCoinList from './AddressCoinList'
import AddressStakingPoolUserList from './AddressStakingPoolUserList'
import AddressUserStakingPoolList from './AddressUserStakingPoolList'
import AddressView from './AddressView'
import BlockList from './BlockList'
import BlockView from './BlockView'
import DifficultyChart from './DifficultyChart'
import TransactionItem from './TransactionItem'
import TransactionView from './TransactionView'

export default { AddressCoinList, AddressView, AddressStakingPoolUserList, AddressUserStakingPoolList, BlockList, BlockView, DifficultyChart, TransactionItem, TransactionView }