import React from 'react';
import {
    message,
    Card,
    Pagination,
    Skeleton,
    Table,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Common from '../Common';
import { doRequest } from '@explorer-utils/request';

import './AddressUserStakingPoolList.less'

const AddressUserStakingPoolList = withRouter(({ match }) => {
    const userAddress = match.params.address;

    const countOfPage = 20;
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        setLoading(true)
        return doRequest.get({
            url: `/api/blockchain/staking/user/${userAddress}/pools`,
            data: {
                page: page - 1,
                count: countOfPage,
            },
        }).then(result => {
            setUsers(result.data.data);
            setTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [userAddress, page]);

    const columns = [
        {
            title: <FormattedMessage id="userstakingpools.address" />,
            dataIndex: 'poolAddress',
            key: 'poolAddress',
            render: (poolAddress) => {
                return (<Common.LinkLabel value={`${poolAddress}`} type="address" copyable={false} compact={true} />)
            },
        },
        {
            title: <FormattedMessage id="userstakingpools.stakingAmount" />,
            dataIndex: 'stakingAmount',
            key: 'stakingAmount',
            render: (stakingAmount) => {
                return <span className="explorer-amount">{stakingAmount} QTC</span>
            },
        },
        {
            title: <FormattedMessage id="userstakingpools.withdrawableAmount" />,
            dataIndex: 'withdrawableAmount',
            key: 'withdrawableAmount',
            render: (withdrawableAmount) => {
                return <span className={"explorer-amount" + (parseFloat(withdrawableAmount) < 10 ? " explorer-amount-gary" : "")}>{withdrawableAmount} QTC</span>
            },
        },
    ];

    const BaseTitle = () => (
        <div className="explorer-userstakingpools-txs-title explorer-title">
            <FormattedMessage id="userstakingpools.title" />
        </div>
    );
    return (
        <div className="explorer-userstakingpools">
            <Skeleton loading={!users}>
                <Card
                    className="explorer-userstakingpools-base"
                    title={<BaseTitle />}
                >
                    <div className="explorer-userstakingpools-address explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="userstakingpools.address" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={userAddress} type="address" />
                        </span>
                    </div>
                    <div className="explorer-userstakingpools-total explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="userstakingpools.total" /></span>
                        <span className="explorer-box-content">{total}</span>
                    </div>
                </Card>
                <Table
                    className="explorer-userstakingpools-table"
                    loading={loading}
                    dataSource={users}
                    columns={columns}
                    pagination={false}
                />
                <Pagination
                    className="explorer-pagination"
                    current={page}
                    total={total}
                    pageSize={countOfPage}
                    onChange={(v) => setPage(v)}
                    showSizeChanger={false}
                />
            </Skeleton>
        </div>
    )
});

export default AddressUserStakingPoolList;