export default {
    // lang
    "lang.en-US": "English",
    "lang.zh-CN": "简体中文",
    // components
    "components.timer.seconds": "s",
    "components.timer.minutes": "m",
    "components.timer.hours": "h",
    "components.timer.days": "d",
    // error
    "error.gotohome": "Back to Home",
    "error.message404": "Not Found",
    // app
    "app.copyright": "Copyright © 2025 qitcoin.io.",
    // header
    "header.homepage": "Home",
    "header.blockchain.blocks": "Blocks",
    "header.blockchain.transactions": "Transactions",
    "header.blockchain.capacity": "Capacity",
    "header.blockchain.accounts": "Accounts",
    "header.blockchain.stakingpools": "Pools",
    "header.blockchain.network.label": " ",
    "header.blockchain.network.mainnet": " ",
    "header.search.placeholder": "Search by Height/Block Hash/Transaction Hash/Address",
    // footer
    "footer.link.org": "Qitcoin Official",
    "footer.wallet.sourcecode": "Qitcoin Source Code",
    "footer.wallet.download": "Download Qitcoin Wallet",
    "footer.link.coinmarketcap":"CoinMarketCap (CMC)",
    "footer.link.feixiaohao":"FeiXiaoHao",
    "footer.link.bitnasdaq":"Bitnasdaq",
    "footer.link.coincarp":"CoinCarp",
    "footer.link.balletcrypto":"Ballet Wallet",
    // dashboard
    "dashboard.network.title": "Network Overview",
    "dashboard.network.name": "Current Network",
    "dashboard.network.height": "Block Height",
    "dashboard.network.blockAge": "Latest Block",
    "dashboard.network.blockSyncing": "Synchronizing",
    "dashboard.network.blockReward": "Block Reward",
    "dashboard.network.totalStaking": "Total Staking",
    "dashboard.network.users": "Address",
    "dashboard.network.epochBlocks": "Epoch Blocks",
    "dashboard.network.nextHalvingBlocks": "Next Halving Blocks",
    "dashboard.network.nextHalvingTime": "Next Halving Time",
    "dashboard.network.mined": "All Mined",
    "dashboard.network.notMined": "Not Mined",
    "dashboard.network.dailyIncome": "Daily Income (100 QTC)",
    "dashboard.network.totalSupply": "Total Supply",
    "dashboard.network.circulation": "Circulation",
    "dashboard.network.circulationRate": "Circulation Rate",
    "dashboard.network.destroyAmount": "Destory Amount",
    "dashboard.difficulty.title": "Difficulty Chart",
    "dashboard.blocks.title": "Latest Blocks",
    // capacity
    "difficulty.range.week": "Week",
    "difficulty.range.month": "Month",
    "difficulty.range.quarter": "Quarter",
    "difficulty.range.year": "Year",
    "difficulty.range.all": "All",
    // block list
    "blocklist.height": "Height",
    "blocklist.time": "Time",
    "blocklist.tx": "Transactions",
    "blocklist.generator": "Generator",
    // block view
    "blockview.title": "Block Summary",
    "blockview.previous": "Previous",
    "blockview.next": "Next",
    "blockview.height": "Height",
    "blockview.hash": "Hash",
    "blockview.time": "Time",
    "blockview.generator": "Generator",
    "blockview.nonce": "Nonce",
    "blockview.deadline": "Deadline",
    "blockview.difficulty": "Difficulty",
    "blockview.reward": "Reward",
    "blockview.txs": "Block Transactions",
    // tx item
    "txitem.time": "Time",
    "txitem.from": "From Address",
    "txitem.to": "To Address",
    "txitem.amount": "Amount",
    "txitem.fee": "Fee",
    "txitem.size": "Size",
    "txitem.coinbase": "Coinbase",
    "txitem.blockhash": "Block Hash",
    "txitem.vout.MinedReward": "Coinbase",
    "txitem.vout.BindPlotter": "Bind Plotter",
    "txitem.vout.Point": "Pledge Point",
    "txitem.vout.Staking": "Staking",
    "txitem.vout.receiver": "Receiver Address: ",
    "txitem.vout.amount": "Effective Amount: ",
    "txitem.vout.lockBlocks": "Lock Blocks: ",
    // address view
    "addressview.title": "Address Summary",
    "addressview.address": "Address",
    "addressview.viewstakingpoolusers": "View Staking Pool Users",
    "addressview.viewuserstakingpools": "View User Staking Pools",
    "addressview.amount": "Amount",
    "addressview.viewcoins": "View Coins",
    "addressview.spendable": "Spendable",
    "addressview.txn": "Transaction Count",
    "addressview.stakingfrom": "Staking Received",
    "addressview.stakingto": "Staking Sent",
    "addressview.pointfrom": "Point Received",
    "addressview.pointto": "Point Sent",
    "addressview.miningblock": "Mined Block of Week",
    "addressview.miningblock.percent": "Percent",
    "addressview.bindcount": "Bind Plotters",
    "addressview.capacity": "Estimate Capacity",
    "addressview.miningrequire": "Pledge Reuire",
    "addressview.txs": "Relate Transactions",
    "addressview.thisABlackHoldAddress": "This is a black hole address",
    // accounts
    "accounts.type.account": "Top Holders",
    "accounts.type.pool": "Top Pools",
    "accounts.NO": "NO.",
    "accounts.address": "Address",
    "accounts.amount": "Amount",
    "accounts.summary.amount": "Amount",
    "accounts.summary.total": "Total",
    // coin list
    "coinlist.title": "Coins Summary",
    "coinlist.address": "Address",
    "coinlist.total": "Total",
    "coinlist.txid": "Transaction",
    "coinlist.amount": "Amount",
    "coinlist.type": "Type",
    "coinlist.type.Coinbase": "Coinbase",
    "coinlist.type.Pay": "Pay",
    "coinlist.type.BindPlotter": "Bind Plotter",
    "coinlist.type.Point": "Point",
    "coinlist.type.Staking": "Staking",
    // user staking pools
    "userstakingpools.title": "User Staking Pools",
    "userstakingpools.address": "Pool Address",
    "userstakingpools.stakingAmount": "Staking Amount",
    "userstakingpools.withdrawableAmount": "Reward Amount",
    "userstakingpools.total": "Total",
    // staking pool users
    "stakingpoolusers.title": "Staking Pool Users",
    "stakingpoolusers.address": "Address",
    "stakingpoolusers.stakingAmount": "Staking Amount",
    "stakingpoolusers.withdrawableAmount": "Reward Amount",
    "stakingpoolusers.total": "Total",
}