import DurtationLabel from './DurtationLabel'
import LinkLabel from './LinkLabel'

const getBlockReward = function(height) {
    if (height < 1) {
        return 0;
    }
    if (height == 1) {
        return 10500000;
    } else {
        height += 2 * (10500000 / 75); // 280000
    }

    const inflate = 10000;

    let subsidy = 0;
    let halvings = Math.floor(height / 700000);
    if (halvings < 64) {
        subsidy = 75 * inflate;
        subsidy = subsidy >> halvings;
    }

    return subsidy / inflate;
}

const getMinedBlocksReward = function(height) {
    let total = 0;
    if (height < 1) {
        return total;
    }
    total += 10500000 * 2;
    if (height < 420000) {
        total += height * 75;
        height = 0;
    } else {
        total += 420000 * 75;
        height -= 420000;

        let subsidy = 75;
        while (height >= 700000) {
            subsidy = subsidy >> 1;
            total += subsidy * 700000;
            height -= 700000;
        }
        if (height > 0 && subsidy > 0) {
            subsidy = subsidy >> 1;
            total += height * subsidy;
        }
    }
    return total;
}

export default { DurtationLabel, LinkLabel, getBlockReward, getMinedBlocksReward }