import React, { Fragment } from 'react';
import {
    message,
    Button,
    Card,
    List,
    Pagination,
    Skeleton,
    Space,
} from 'antd';
import {
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TransactionItem from './TransactionItem';
import Common from '../Common';
import { formatTime } from '@explorer-utils/format';
import { doRequest } from '@explorer-utils/request';

import './BlockView.less'

const BlockView = withRouter(({ history, match }) => {
    const heightOrHash = match.params.blockId;

    const [loading, setLoading] = React.useState(false);
    const [block, setBlock] = React.useState(null);

    const txCountOfPage = 5;
    const [loadingTx, setLoadingTx] = React.useState(false);
    const [transactions, setTransactions] = React.useState(null);
    const [txPage, setTxPage] = React.useState(1);
    const [txTotal, setTxTotal] = React.useState(0);

    React.useEffect(() => {
        // reset tx page
        setTxPage(1);

        setLoading(true);
        doRequest.get({
            url: `/api/blockchain/block/${heightOrHash}`,
        }).then(result => {
            setBlock(result.data);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [heightOrHash]);
    React.useEffect(() => {
        setLoadingTx(true);
        doRequest.get({
            url: `/api/blockchain/block/${heightOrHash}/transaction`,
            data: {
                page: txPage - 1,
                count: txCountOfPage,
            }
        }).then(result => {
            setTransactions(result.data.data);
            setTxTotal(result.data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoadingTx(false));
    }, [heightOrHash, txPage]);

    const BaseTitle = () => (
        <Space>
            <div className="explorer-blockview-base-title explorer-title">
                <FormattedMessage id="blockview.title" />
            </div>

            <Space className="explorer-blockview-base-navi">
                <Button className="explorer-blockview-base-previous" icon={<LeftOutlined />} disabled={!block.previous} shape="round" size="small"
                    title={block ? block.previous : ''}
                    onClick={() => history.push(`/blockchain/block/${block.previous}`)}>
                    <FormattedMessage id="blockview.previous" />
                </Button>
                <Button className="explorer-blockview-base-next" icon={<RightOutlined />} disabled={!block.next} shape="round" size="small"
                    title={block ? block.next : ''}
                    onClick={() => history.push(`/blockchain/block/${block.next}`)}>
                    <FormattedMessage id="blockview.next" />
                </Button>
            </Space>
        </Space>
    );
    const TransactionsTitle = () => (
        <div className="explorer-blockview-txs-title explorer-title">
            <Space>
                <span><FormattedMessage id="blockview.txs" /></span>
                <span>({txTotal})</span>
            </Space>
        </div>
    );
    return (
        <div className="explorer-blockview">
            <Skeleton loading={loading || !block}>
                <Card
                    className="explorer-blockview-base"
                    title={<BaseTitle />}
                >
                    <div className="explorer-blockview-height explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="blockview.height" /></span>
                        <span className="explorer-box-content">{block ? block.height : ''}</span>
                    </div>
                    <div className="explorer-blockview-hash explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="blockview.hash" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={block ? block.hash : ""} type="block" />
                        </span>
                    </div>
                    <div className="explorer-blockview-time explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="blockview.time" /></span>
                        <span className="explorer-box-content">{block ? formatTime(block.time) : ''}</span>
                    </div>
                    <div className="explorer-blockview-generator explorer-box">
                        <span className="explorer-box-title"><FormattedMessage id="blockview.generator" /></span>
                        <span className="explorer-box-content">
                            <Common.LinkLabel value={block ? block.generator : ""} type="address" />
                        </span>
                    </div>
                    <Fragment>
                        <div className="explorer-blockview-nonce explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="blockview.nonce" /></span>
                            <span className="explorer-box-content">{block ? block.nonce : ''}</span>
                        </div>
                        <div className="explorer-blockview-deadline explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="blockview.deadline" /></span>
                            <span className="explorer-box-content">
                                <Space>
                                    <span>{block ? <Common.DurtationLabel durtation={block.deadline} visibility={{ day: false, hour: false }} /> : ''}</span>
                                    <span>({block ? block.deadline : ''} <FormattedMessage id="components.timer.seconds" />)</span>
                                </Space>
                            </span>
                        </div>
                        <div className="explorer-blockview-difficulty explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="blockview.difficulty" /></span>
                            <span className="explorer-box-content">{block ? block.difficulty : ''}</span>
                        </div>
                    </Fragment>
                    {transactions && transactions.length > 0 && (
                        <div className="explorer-blockview-reward explorer-box">
                            <span className="explorer-box-title"><FormattedMessage id="blockview.reward" /></span>
                            <span className="explorer-box-content">{transactions && transactions.length > 0 ? transactions[0].amount + ' QTC' : 'N/A'}</span>
                        </div>
                    )}
                </Card>
            </Skeleton>
            <Skeleton loading={loading || !transactions}>
                <Card
                    className="explorer-blockview-txs"
                    title={<TransactionsTitle />}
                >
                    <List
                        loading={loadingTx}
                        dataSource={transactions}
                        renderItem={(item, index) => <TransactionItem data={item} index={(txPage - 1) * txCountOfPage + index} compact={true} />}
                    />
                    <Pagination
                        className="explorer-pagination"
                        current={txPage}
                        total={txTotal}
                        pageSize={txCountOfPage}
                        onChange={(v) => setTxPage(v)}
                        showSizeChanger={false}
                    />
                </Card>
            </Skeleton>
        </div>
    )
});

export default BlockView;