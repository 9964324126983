import React from 'react';
import { message, Image, Input, Menu, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { getLocale, setLocale } from '@explorer-locales';

import { doRequest } from '@explorer-utils/request';

const Header = withRouter(({ history }) => {
    const [searching, setSearching] = React.useState(false);

    const toKey = (pathname) => {
        let ps = pathname.split("/");
        if (ps.length > 2) {
            return "/" + ps[1] + "/" + ps[2]
        } else {
            return "/" + ps[1]
        }
    }
    const [selectedKey, setSelectedKey] = React.useState(() => toKey(history.location.pathname));
    React.useEffect(() => history.listen(({ pathname }) => setSelectedKey(toKey(pathname))), [history, history.pathname]);

    const doSearch = (text) => {
        const formatText = text.trim();
        if (formatText === '') {
            return
        }

        if (formatText.length === 34 && formatText.startsWith("3")) {
            // address
            history.push(`/blockchain/address/${formatText}`);
        } else if (formatText.length === 64) {
            // hash
            setSearching(true);
            doRequest.get({
                url: `/api/blockchain/hash/${formatText}`,
            }).then(({data}) => {
                if (data.type === 'block') {
                    history.push(`/blockchain/block/${data.data.hash}`);
                } else if (data.type === 'transaction') {
                    history.push(`/blockchain/transaction/${data.data.txid}`);
                } else if (data.type === 'address') {
                    history.push(`/blockchain/address/${data.data.address}`);
                } else {
                    message.error(`Invalid type: ${data.type}`);
                }
            }).catch(({ code, error }) => {
                message.error(error + " (" + code + ")");
            }).finally(() => setSearching(false));
        } else if (formatText.match(/^[0-9]+$/g)) {
            if (formatText.length < 9) {
                // block height
                setSearching(true);
                doRequest.get({
                    url: `/api/blockchain/block/${formatText}`,
                }).then(({data}) => {
                    if (data.hash !== undefined) {
                        history.push(`/blockchain/block/${data.hash}`);
                    } else {
                        message.error("Invalid Input");
                    }
                }).catch(({ code, error }) => {
                    message.error(error + " (" + code + ")");
                }).finally(() => setSearching(false));
            }
        } else {
            message.error("Invalid Input");
        }
    }

    return (
        <div className="explorer-header">
            <div className="explorer-header-top">
                <Image className="explorer-logo" src="/static/images/logo_v1.png" preview={false} />
                <div className="explorer-name">
                    Qitcoin Explorer
                </div>
                <div className="explorer-network">
                    <FormattedMessage id="header.blockchain.network.label" />
                    <FormattedMessage id="header.blockchain.network.mainnet" />
                </div>
                <Select className="explorer-languages"
                    size="small"
                    defaultValue={getLocale().lang}
                    onChange={(lang) => setLocale(lang)}
                >
                    <Select.Option value="en-US">
                        <FormattedMessage id="lang.en-US" />
                    </Select.Option>
                    <Select.Option value="zh-CN">
                        <FormattedMessage id="lang.zh-CN" />
                    </Select.Option>
                </Select>
            </div>
            <div className="explorer-header-bottom">
                <Menu
                    className="explorer-header-nav"
                    mode="horizontal"
                    selectable={false}
                    selectedKeys={[selectedKey]}
                >
                    <Menu.Item key="/">
                        <Link to="/">
                            <FormattedMessage id="header.homepage" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/blockchain/blocks">
                        <Link to="/blockchain/blocks">
                            <FormattedMessage id="header.blockchain.blocks" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/blockchain/transactions">
                        <Link to="/blockchain/transactions">
                            <FormattedMessage id="header.blockchain.transactions" />
                        </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="/blockchain/capacity">
                        <Link to="/blockchain/capacity">
                            <FormattedMessage id="header.blockchain.capacity" />
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key="/blockchain/accounts">
                        <Link to="/blockchain/accounts">
                            <FormattedMessage id="header.blockchain.accounts" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/blockchain/stakingpools">
                        <Link to="/blockchain/stakingpools">
                            <FormattedMessage id="header.blockchain.stakingpools" />
                        </Link>
                    </Menu.Item>
                </Menu>
                <FormattedMessage id="header.search.placeholder">
                    {(msg) =>
                        <Input.Search
                            className="explorer-header-search"
                            placeholder={msg}
                            onSearch={(text) => doSearch(text)}
                            loading={searching}
                        />
                    }
                </FormattedMessage>
            </div>
        </div>
    );
})

export default Header