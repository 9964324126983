import React from 'react';
import {
    message,
    Card,
    Skeleton,
    Space,
} from 'antd';
import {
    BarChartOutlined,
    CodeSandboxOutlined,
    UnorderedListOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Blockchain, Common } from '@explorer-components';
import { doRequest } from '@explorer-utils/request';

import './index.less';

const Dashboard = () => {
    const mainnet22DestroyAmount = 37.5 * 0.8 * 86400; // 2592000

    const [network, setNetwork] = React.useState(null);
    const [blockAge, setBlockAge] = React.useState(0);
    const [minedBlocksReward, setMinedBlocksReward] = React.useState(0);

    React.useEffect(() => {
        const updateNetwork = () => {
            return doRequest.get({
                url: '/api/blockchain/network'
            }).then(result => {
                setNetwork(result.data);
            }).catch(({ code, error }) => {
                message.error(error + " (" + code + ")");
            });
        };

        updateNetwork();

        let updateNetworkTimerId = setInterval(() => updateNetwork(), 5 * 60 * 1000);
        return () =>  {
            clearInterval(updateNetworkTimerId);
        }
    }, []);
    React.useEffect(() => {
        if (network && network.best) {
            setMinedBlocksReward(Common.getMinedBlocksReward(network.best.height));
        }

        let updateBlockAgeTimerId = setInterval(() => network && setBlockAge(Math.max(new Date().getTime() / 1000 - network.best.time, 0)), 1000);
        return () =>  {
            clearInterval(updateBlockAgeTimerId);
        }
    }, [network])

    const NetworkTitle = () => (
        <Space>
            <CodeSandboxOutlined />
            <FormattedMessage id="dashboard.network.title" />
        </Space>
    );
    const DifficultyTitle = () => (
        <Space>
            <BarChartOutlined />
            <FormattedMessage id="dashboard.difficulty.title" />
        </Space>
    );
    const BlocksTitle = () => (
        <Space>
            <UnorderedListOutlined />
            <FormattedMessage id="dashboard.blocks.title" />
        </Space>
    );
    return (
        <div className="explorer-dashboard">
            <Card
                className="explorer-dashboard-network"
                title={<NetworkTitle />}
            >
                <Skeleton loading={!network}>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.name" /></span>
                        <span className="network-box-content">{network ? network.name : ' - '}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.height" /></span>
                        <span className="network-box-content">{network && network.best ? network.best.height : ' - '}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.blockAge" /></span>
                        <span className="network-box-content">
                            {blockAge < 3600 
                                ? (blockAge > 0 ? <Common.DurtationLabel durtation={blockAge} visibility={{ day: false, hour: false }} /> : ' - ')
                                : <FormattedMessage id="dashboard.network.blockSyncing" />
                            }
                        </span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.blockReward" /></span>
                        <span className="network-box-content">{network && network.best ? new Number(Common.getBlockReward(network.best.height)).toFixed(2) : ' - '} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.users" /></span>
                        <span className="network-box-content">{network ? network.holder_count : ' - '}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.epochBlocks" /></span>
                        <span className="network-box-content">{network ? network.epoch_blocks : ' - '} ({<Common.DurtationLabel durtation={network ? network.epoch_blocks * 180 : 0} visibility={{ day: false, hour: true, minute: false, second: false }} />})</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.nextHalvingBlocks" /></span>
                        <span className="network-box-content">{network ? network.next_halving_blocks : ' - '}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.nextHalvingTime" /></span>
                        <span className="network-box-content">{network ? <Common.DurtationLabel durtation={network.next_halving_time} visibility={{ minute: false, second: false }} /> : ' - '}</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.mined" /></span>
                        <span className="network-box-content">{network && network.best ? new Number(minedBlocksReward).toLocaleString('en-US', {useGrouping:true}) : ' - '} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.notMined" /></span>
                        <span className="network-box-content">{network && network.best ? new Number(105_000_000 - minedBlocksReward).toLocaleString('en-US', {useGrouping:true}) : ' - '} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.totalStaking" /></span>
                        <span className="network-box-content">{network ? new Number(parseInt(network.total_staking) || 0).toLocaleString('en-US', {useGrouping:true}) : ' - '} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.dailyIncome" /></span>
                        <span className="network-box-content">{network && network.total_staking > 0 ? new Number(480 * network.block_reward * 100 / network.total_staking * 0.6).toFixed(4) : ' - '} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.totalSupply" /></span>
                        <span className="network-box-content">105,000,000 QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.circulation" /></span>
                        <span className="network-box-content">{network && network.destroy_amount ? new Number(minedBlocksReward - parseInt(network.total_staking) - parseInt(network.destroy_amount) - mainnet22DestroyAmount).toLocaleString('en-US', {useGrouping:true}) : ' - '} QTC</span>
                    </div>
                    <div className="network-box">
                        <span className="network-box-title"><FormattedMessage id="dashboard.network.circulationRate" /></span>
                        <span className="network-box-content">{network && network.destroy_amount && minedBlocksReward ? new Number((minedBlocksReward - parseInt(network.total_staking) - parseInt(network.destroy_amount) - mainnet22DestroyAmount) / 105_000_000 * 100).toFixed(1) : ' - '}%</span>
                    </div>
                    <div className="network-box">
                        <a className="network-box-title" href="/blockchain/address/334L4RPCoYrrzLZ57xMEz64xgtVEyHNMAX">
                            <FormattedMessage id="dashboard.network.destroyAmount" />
                            <InfoCircleOutlined />
                        </a>
                        <span className="network-box-content">{network && network.destroy_amount ? new Number(parseInt(network.destroy_amount) + mainnet22DestroyAmount).toLocaleString('en-US', {useGrouping:true}) : ' - '} QTC</span>
                    </div>
                </Skeleton>
            </Card>
            <Card
                className="explorer-dashboard-difficulty"
                title={<DifficultyTitle />}
            >
                <Blockchain.DifficultyChart />
            </Card>
            <Card
                className="explorer-dashboard-blocks"
                title={<BlocksTitle />}
            >
                <Blockchain.BlockList count={10} pagination={false} />
            </Card>
        </div >
    );
};

export default Dashboard;