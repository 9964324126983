import React from 'react';
import {
    message,
    Table,
    Pagination,
    Radio,
    Skeleton,
} from 'antd';
import { Common } from "@explorer-components";
import { FormattedMessage } from 'react-intl';
import { doRequest } from '@explorer-utils/request';

import './index.less';

const AccountsPage = ({optionType = "account"}) => {
    const countOfPage = 20;
    const [loading, setLoading] = React.useState(false);
    const [accounts, setAccounts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        const mapUrls = {
            account: `/api/blockchain/account`,
            stakingpool: `/api/blockchain/staking/pools`,
        };

        setLoading(true);
        doRequest.get({
            url: mapUrls[optionType],
            data: {
                page: page - 1,
                count: countOfPage,
            }
        }).then(({ data }) => {
            setAccounts(data.data);
            setTotal(data.total);
        }).catch(({ code, error }) => {
            message.error(error + " (" + code + ")");
        }).finally(() => setLoading(false));
    }, [optionType, page]);

    const columns = [
        {
            title: <FormattedMessage id="accounts.NO" />,
            dataIndex: 'address',
            key: 'address',
            render: (address, record, index) => {
                return <span>#{index + (page - 1) * countOfPage + 1}</span>
            },
        },
        {
            title: <FormattedMessage id="accounts.address" />,
            dataIndex: 'address',
            key: 'address',
            render: (address) => {
                if (optionType === "stakingpool") {
                    return (<Common.LinkLabel value={address} suffix="/stakingpoolusers" type="address" copyable={false} />)
                }
                return (<Common.LinkLabel value={address} type="address" copyable={false} />)
            },
        },
        {
            title: <FormattedMessage id="accounts.amount" />,
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, record) => {
                return <span className="explorer-amount">{record['stakingAmount'] || amount} QTC</span>
            },
        },
    ];

    return (
        <div className="explorer-accounts">
            <Table
                className="explorer-accounts-list"
                loading={loading}
                dataSource={accounts}
                columns={columns}
                pagination={false}
            />
            <Pagination
                className="explorer-pagination"
                current={page}
                total={total}
                pageSize={countOfPage}
                onChange={(v) => setPage(v)}
                showSizeChanger={false}
            />
        </div>
    )
};

export default AccountsPage;