import React from 'react';
import { Layout } from 'antd';
import {
    BrowserRouter as Router,
    Switch,
    Route as PublicRoute,
} from 'react-router-dom'

import './App.less';

import { Blockchain, Error, Frame } from "@explorer-components";
import { AccountsPage, BlocksPage, DashboardPage, TransactionsPage } from "@explorer-pages";

const { Header, Content, Footer } = Layout;

const App = () => {
    return (
        <React.Fragment>
            <Router>
                <Layout className="explorer-app">
                    <Header className="explorer-frame-header">
                        <Frame.Header />
                    </Header>
                    <Content className="explorer-frame-content">
                        <Switch>
                            <PublicRoute exact path="/" component={DashboardPage} />
                            <PublicRoute exact path="/blockchain/blocks" component={BlocksPage} />
                            <PublicRoute exact path="/blockchain/transactions" component={TransactionsPage} />
                            <PublicRoute exact path="/blockchain/capacity" component={Error.NotFound} />
                            <PublicRoute exact path="/blockchain/accounts" component={() => <AccountsPage optionType = "account"/>} />
                            <PublicRoute exact path="/blockchain/stakingpools" component={() => <AccountsPage optionType = "stakingpool"/>} />

                            <PublicRoute exact path="/blockchain/block/:blockId" component={Blockchain.BlockView} />
                            <PublicRoute exact path="/blockchain/transaction/:txid" component={Blockchain.TransactionView} />
                            <PublicRoute exact path="/blockchain/address/:address" component={Blockchain.AddressView} />
                            <PublicRoute exact path="/blockchain/address/:address/coins" component={Blockchain.AddressCoinList} />
                            <PublicRoute exact path="/blockchain/address/:address/stakingpoolusers" component={Blockchain.AddressStakingPoolUserList} />
                            <PublicRoute exact path="/blockchain/address/:address/userstakingpools" component={Blockchain.AddressUserStakingPoolList} />

                            <PublicRoute path="*" component={Error.NotFound} />
                        </Switch>
                    </Content>
                    <Footer className="explorer-frame-footer">
                        <Frame.Footer />
                    </Footer>
                </Layout>
            </Router>
        </React.Fragment>
    )
}

export default App;
